.orderProcessHeader{
    position: fixed;
    width:100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    background-color: #f3f5f7;
    align-items: center;
    padding: 16px;
    /*padding: 0px 16px 0px 32px;*/
    margin-bottom: 16px;
    z-index: 1021;
}
.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1020;
    top: 0;
    left: 0;
    /*background-color: rgb(0,0,0);*/
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    animation-name: popAnim;
    animation-duration: 0.2s;
}
.orderProcessContainer{
    display: flex;
    height: 100%;
    flex-direction: row;
}
.processTitle{
    /*background-color: #fff;*/
    padding:16px;
    display: flex;
    flex-direction: row;
    height: 100%;
}
.processTitleDone{
    background-color: #fff;
    margin: 16px 0px;
}
.orderProcessBody{
    background-color: #fff;
    padding: 16px;
}
.processIcon{
    background-color: #fff;
    width:40px!important;
    height: 40px;
    padding: 7px 9px;
    border-radius: 20px;
    border: 1px solid grey;
}
.processIconDone{
    background-color: #388E3C;
}
.processBox{
    display: flex;
    flex-direction:row;
    border-bottom: 1px solid #C94B4B;
}
.processTab{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
}
.processTabSelect{
    flex:1;
    display: flex;
    flex-direction: row;
    /*justify-content: center;*/
    /*align-items: center;*/
    padding: 8px 0;
    border-bottom: 2px solid #C94B4B;
}
.processTabComp{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 8px;
    border: 2px solid green;
    background-color: rgba(0,128,0,0.1);
    border-radius: 4px;
    margin: 8px;
}
.processSelect{
    color: rgb(0,128,0);
    font-weight: 600;
    font-size: 20px;
    margin-left: 16px;
}
.processText{
    color: #B7B7B7;
    font-weight: 600;
    font-size: 20px;
    margin-left: 16px;
}
.orderSuccessContainer{
    height: 90%;
    background-color: #fff;
    /*background-image: url("../components/assets/img/opbg.png");*/
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex:1;
}
.orderInfo{
    flex:1;
    /*background-color:#678;*/
    /*padding: 32px;*/
    /*margin: 16px 350px 16px 16px;*/
    padding: 36px 0px;
    margin: 32px 350px 0 0;
}
.summery{
    padding: 36px 0;
    margin: 16px 0;
    position: fixed;
    right: 0;
    background: #ffffff;
    height: 100%;
    top:26px;
}
.summeryText{
    color: #C94B4B;
    font-size: 28px;
    font-weight: 700;
    margin-left: 16px
}
.summeryInfo{
    width: 100%;
    /* background-color: #fff; */
    padding: 0 16px;
    /*margin: 0 16px;*/
    /*max-height: 80vh;*/
    /*overflow-y: scroll;*/
}
.summeryDiv{
    display: flex;
    flex-direction: row;
    padding:8px 0px;
}
.summeryItem{
    font-size: 18px;
    flex:1
}
.summeryValue{
    font-size: 18px;
    font-weight: 600;
    margin-right: auto;
}
.summeyInfoText{
    font-size:16px;
    font-weight: 600;
    color:grey;
}
.orderButtonDiv{
    text-align: center;
    width: 100%;
    margin: 8px 0px;
    background: linear-gradient(to right, #C94B4B, #4B134F);
    -webkit-transition: transform 0.2s; /* For Safari 3.1 to 6.0 */
    transition: transform 0.2s;
    font-size: 18px;
    font-weight: 700;
    color:#fff;
}

.orderButtonDiv:hover{
    cursor: pointer;
    /*transform: scale(1.05);*/
}
.otherOffers{
    padding:16px 0px;
}
.offerDecs{
    text-align: center;
    font-size: 12px;
}
.offerDecsButton{
    display: flex;
    flex-direction: row;
}
.offerDecsButton div{
    flex: 1;
    color: #b7b7b7;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 0px;
    text-align: center;
    cursor: pointer;
}
.offerDecsButton div:hover{
    color:#C94B4B;
}
.extraDiv{
    padding: 8px;
    border:1px solid lightgrey;
    border-radius: 10px;
}
.extraDivText{
    font-size: 16px;
    cursor: pointer;
    color: #C94B4B;
    font-weight: 600;
    padding: 8px 0;
    text-align: center;
}
.processInputR{
    flex:1;
    padding:8px 8px 8px 0px;
}
.processInputU{
    flex:1;
    padding:8px 0px;
}
.processInputL{
    flex:1;
    padding:8px 0px 8px 8px;
}
.processDetailsDiv{
    display: flex;
    flex-direction: row;
    padding:8px 0px;
}
.processDetailText{
    font-size: 16px;
    font-weight: 600;
    flex:1;
    color:#b7b7b7;
}
.processButtons{
    /*padding: 16px 0px;*/
    text-align: right;
}
.confirmButton{
    margin: 16px 0;
    background-color: #C94B4B;
    color:#fff;
    font-weight: 600;
}
.processDetailsButton{
    padding: 8px;
    /* border: 1px solid #17a2b8; */
    font-size: 11px;
    color: #ffffff;
    border-radius: 6px;
    background-color: #C94B4B;
    transition: transfrom 0.2s;
    -webkit-transition: transfrom 0.2s;
}
.processDetailsButton:hover{
    font-weight: 700;
    cursor:pointer;
    box-shadow: 0px 1px 4px rgba(0,0,0,0.2);
    transform: scale(1.05);
}
.processButtonText{
    font-size: 12px;
    color:#17a2b8;
    font-weight: 600;
    margin-top: 4px;
    cursor: pointer;
}
.addressSelect{
    font-size: 16px;
    cursor: pointer;
    padding: 8px;
    align-items: center;
    color: #9B9B9B;
    font-weight: 600;
}
.selectionContainer{
    /*cursor: pointer;*/
    height: 100px;
    /*width: 200px;*/
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    margin: 16px;
    /*transform: scale(1);*/
    transition:height 0.2s;
}
.selectionContainer:hover {
    transition:height 0.2s;
    transform: scale(1.05);
    cursor: pointer;
    box-shadow: 0px 1px 4px rgba(0,0,0,0.2);
}
.orderCompleteImage{
    margin: 32px auto;
    width:250px!important;
}