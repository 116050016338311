.eyeMain{
    /*background: url(../images/bg.png) no-repeat center center fixed;*/
    background-size: cover;
    /*height: 100%;*/
    overflow: hidden;
    background: linear-gradient(#000, #24243A);
    width: 100%;
    /*height: 100vh;*/
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 64px;
}
.eyeMainBlack{
    /*background: url(../images/bg.png) no-repeat center center fixed;*/
    background-size: cover;
    /*height: 100%;*/
    overflow: hidden;
    background-color:#000;
    width: 100%;
    /*height: 100vh;*/
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 64px;
}
.eyeMainGray{
    /*background: url(../images/bg.png) no-repeat center center fixed;*/
    background-size: cover;
    /*height: 100%;*/
    overflow: hidden;
    background: linear-gradient(#24243A, #000);
    width: 100%;
    /*height: 100vh;*/
    display: flex;
    flex: 1;
    flex-direction: column;

}
.eyeMainRev{
    /*background: url(../images/bg.png) no-repeat center center fixed;*/
    background-size: cover;
    /*height: 100%;*/
    overflow: hidden;
    background-color:#000;
    width: 100%;
    /*height: 100vh;*/
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 64px;
}
.screenImage{
    display: flex;
    flex-direction: row;
    padding: 72px 16px;
    justify-content: center;
    align-items: center;
}
.secondSection{
    /*background: url(../../assets/imageIcon/middleBg.png) no-repeat center center;*/
    text-align: center;
    padding: 50px;
    background: linear-gradient(#000, #24243A);
    /*height: 300px;*/
    /*flex: 1;*/
}
.circle1{
    position: absolute;
    height: 500px;
    bottom: 5%;
}
.circle2{
    position: absolute;
    height: 500px;
    right: 0;
    top: 20%;
}
.circle3{
    position: absolute;
    height:100px;
    top: 30%;
    left: 15%;
}
.circle4{
    position: absolute;
    height: 125px;
    right: 10%;
    top: 80%;
}
.videoLink{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    width: 75%!important;
    margin: 64px auto;
    border-width: 2cm;
    border-radius: 20px;
}
.headerText{
    text-align: center;
    font-weight: 200;
    font-size: 42px;
    color:#fff;
    /*padding: 8px 0;*/
}
.gifStyle{
    height: 75%;
    width: 75%;
    margin:0px auto;
}
.headerRed{
    color:#C94B4B!important;
    font-weight: 600;
}
.headerSmallText{
    text-align: center;
    font-size: 24px;
    color:#b7b7b7;
    /*padding: 8px 0;*/
}
.lightText{
    text-align: center;
    padding: 0 24px;
    color: #000;
    font-size: 16px;
}
.headerLarge{
    font-size: 64px;
    text-align: center;
}
.bottomImage{
    margin: 32px auto;
    justify-content: center;
    align-items: center;
    display: flex;
    height: auto;
    width: 100%;
}
.bulletColumn{
    flex: 1;
    /*justify-content: center;*/
    /*align-items: center;*/
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin:0px auto;
}
.bulletPoints{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
}
.bulletImage{
    height: 20px;
    width: 20px;
}
.black-color{
    color: black;
}
.font-size{
    font-size: 100;
}
.ftrLink{
    color:#fff!important;
    font-size: 14px;
    width: 160px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ftrLink2{
    color:#fff!important;
    font-size: 14px;
    width: 104px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ftrLink3{
    color:#fff!important;
    font-size: 14px;
    width: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
.ftrLink4{
    color:#fff!important;
    font-size: 14px;
    width: 50px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
.ftrText{
    text-align: center;
}
.mainBody{
    display: flex;
    flex-direction: row;
}
.eyeContainer{
    display: flex;
    flex-direction: row;
    /*flex: 1;*/
    /*align-items: center;*/
}
.eyelittleText{
    margin: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #b7b7b7;
}
.eyeBigText{
    margin: 8px 0;
    font-size: 42px;
    font-weight: 700;
    color:#333;
}
.eyeButton{
    flex:1;
    margin: 24px 8px 24px 0;
    border-radius: 50px!important;
    background-color: #C94B4B;
    background-image: linear-gradient(90deg, #C94B4B, #4B134F);
    padding: 8px 0!important;
    border: 1px solid #fff;
    display: flex!important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.navbarHdr{
    /*background-image: linear-gradient(90deg,#b8434c,#5a1a4f);*/
    /*background: url(../../assets/imageIcon/footer.png) no-repeat center center;*/
    /*background-size: cover;*/

    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    background-color: #000;
}
.icons{
    height: 18px;
    width:18px;
}
.navIcon{
    width: 200px;
    margin-right: 15px;
}
.gapHome{
    width: 400px;
    margin: 20px 16px!important;
}
.gapHome2{
    width: 300px;
    margin: 20px 16px!important;
    box-shadow:  rgba(0,0,0,.15)!important;
}
.gapHome3{
    width: 74px;
    margin: 16px 10px!important;
    box-shadow:  rgba(0,0,0,.15)!important;
}
.cards{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.eyeButton:hover{
    border: 1px solid #4B134F;
    background-color:#fff!important;
    background-image: none;
}
.imagePlace{
    margin-left: 30%;
    width: 70%;
    height: auto;
}
.btnText{
    margin-left: 8px;
    font-size: 16px;
    color:#fff;
    font-weight: 600;
}
.eyeButton:hover .btnText{
    color: #4B134F;
}
.eyeTop{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}
.hslogo{
    width: 150px;
    height: auto;
}
.busBtn{
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-right: 16px;
    color: #4B134F;
    padding: 8px;
    border-radius: 50px;
    border: 1px solid #4B134F;
    margin-bottom: 16px;
    background-color:#fff;
    transition: 0.2s color, background-color;
    text-align: center;
}
.busBtn:hover{
    background-color: #4B134F;
    color:#fff;
    cursor: pointer;
}
.infoText{
    font-size: 24px;
    color:#000;
    font-weight: 600;
    margin: 8px;
}
.phoneImage{
    display: none;
}
@media only screen and (max-width: 1366px){
    .circle1{
        height: 300px;
        bottom: -10%;
    }
    .circle2{
        height: 300px;
        top: 20%;
    }
    .circle3{
        height:50px;
        top: 30%;
        left: 15%;
    }
    .circle4{
        height: 55px;
        right: 10%;
        top: 80%;
    }
}

@media only screen and (max-width: 992px) {
    .homeImage{
        height: auto!important;
        width: 100%!important;
    }
    .bar{
        display: none!important;
    }
}
@media only screen and (max-width: 767px) {

    .ftrText{
        text-align: center;
    }
    .navbarFtr{
        justify-content: center!important;
        align-items: center!important;
        text-align: center!important;
        flex-direction: column;
    }
    .hslogo{
        margin: auto;
    }
    .eyeContainer{
        flex-direction: column-reverse;
    }
    .eyeTop{
        margin-top: 24px;
        text-align: center;
    }
    .imagePlace{
        margin-left: auto;
        width: 100%;
    }
    .eyeMain{
        height: auto;
    }
    .eyeBigText{
        font-size: 32px;
    }
    .gifStyle{
        height: auto;
        width: 100%;
    }
    .headerText{
        font-size: 28px!important;
    }
    .headerSmallText{
        font-size: 16px;
    }
    .circle1{
        height: 140px;
        bottom: 25%;
    }
    .circle2{
        height: 150px;
        top: 20%;
    }
    .circle3{
        height:20px;
        top: 30%;
        left: 15%;
    }
    .circle4{
        height: 25px;
        right: 10%;
        top: 60%;
    }
    .videoLink{
        width: 100%!important;
    }
    .screenImage{
        flex-direction: column;
    }
}
@media only screen and (max-width: 380px) {
    .gifStyle{
        display: none;
    }
    .phoneImage{
        display: block;
        height: auto;
        width: 100%;
    }
    .circle1{
        height: 110px;
        bottom: 35%;
    }
}