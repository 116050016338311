.contactBg{
    background-size: contain;
    /*height: 100%;*/
    overflow: hidden;
    background-color: #fdfdfd;
    width: 100%;
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 64px;
}
.contactText{
    display: flex;
    flex:1;
    flex-direction: column;
    margin-left: 164px;
}
.textSection{
    margin: 16px 0;
}
.contactHeader{
    font-size: 16px;
    font-weight: 700;
    color:#1eaf94;
}
.contactValue{
    font-size: 20px;
    color:#333;
}
.contactSmall{
    font-size: 16px;
    color:#333;
}
.contactImage{
    width: 75%;
}
@media only screen and (max-width: 992px) {
    .contactBg{
        flex-direction: column;
        height: 100%;
    }
    .contactImage{
        height: auto;
        width: 100%;
    }
}
@media only screen and (max-width: 776px) {
    .contactBg{
        flex-direction: column;
        padding: 32px 16px;
    }
    .contactText{
        /*justify-content: center;*/
        /*align-items: center;*/
        margin-left: 0px;
    }
}