.signinContainer{
    margin-top:32px;
}
.authContainer{
    width: 450px;
    margin: 16px auto;
    padding: 32px;
    border:1px solid #ababc0;
    border-radius: 10px;
}
.authTitle{
    font-size: 20px;
    text-align: center;
}
.mobileCode{
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    font-weight: 700;
    margin-right: 16px;
    line-height: 1.5;
    border-radius: .3rem;
    border: 1px solid #ced4da;
}
.highlight{
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.authLittle{
    font-size: 12px;
    text-align: center;
}
.buttonAuthDiv {
    position:relative;
}
.buttonAuth{
    position: absolute;
    right: -38px;
    top: -42px;
    background-color: #fff;
    width:25px!important;
    height: 25px;
    padding: 5px;
    border-radius: 15px;
    border: 1px solid red;
}
.resendButton{
    margin: 16px auto;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: no-drop;
}
.extraButtonAuth{
    position: absolute;
    right: -16px;
    top: -20px;
    background-color: #fff;
    width:25px!important;
    height: 25px;
    padding: 5px;
    border-radius: 15px;
    border: 1px solid red;
}

.overlayModal {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1049;
    top: 0;
    left: 0;
    /*background-color: rgb(0,0,0);*/
    background-color: rgba(0,0,0, 0.4);
    overflow-x: hidden;
    transition: 0.5s;
    animation-name: popAnim;
    animation-duration: 0.2s;
}
.overlayContainer{
    position: relative;
    top: 25%;
    width: 400px;
    margin-right: auto;
    margin-left: auto;
    padding: 32px;
    text-align: center;
    margin-top: 30px;
    background-color: #fff;
}
.overlayClose{
    position: absolute;
    right: -16px;
    top: -16px;
    background: white;
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid red;
    cursor: pointer;
}
.textBoxProfile{
    margin: 4px 0;
}
@keyframes popAnim {
    0% { transform: scale(0,0); opacity: 0;}
    100%   { transform: scale(1,1); opacity: 1;}
}
.numberHide::-webkit-outer-spin-button,
.numberHide::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}