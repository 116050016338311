.hdrDiv{
    display:flex;
    flexDirection:row;
    height:60px;
    width:100%;
    background: linear-gradient(to right, #C94B4B, #4B134F);
}
.logo{
    height:48px;
    width:48px;
    margin-right:16px;
    object-fit:contain;
    margin-top: 5px;
}
.hdrText{
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    flex: 1;
    overflow-x:hidden ;
}