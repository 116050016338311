
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
.businessBg{
    background: url(../../assets/imageIcon/businessbg.png) no-repeat center center;
    background-size: cover;
    /*height: 100%;*/
    overflow: hidden;
    background-color: #fff;
    width: 100%;
    justify-content: center;
    align-items: center;
    /*height: 100vh;*/
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 64px;
}
body{
    font-family: 'Lato', sans-serif;
}
.section{
    height: 100vh;
}
.sectionMain{
    height: 100vh;
    overflow: hidden;
}
.common{
    display: flex;
    flex-direction: row;
    /*flex-wrap: wrap;*/
}
.commonWrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.center{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.jCenter{
    display: flex;
    justify-content: center;
}
.aCenter{
    display: flex;
    align-items: center;
}

.flexOne{
    margin: 32px;
}
.homeFlex{
    flex:1;
    display: flex;
    margin-left: 64px;
    align-items: flex-start;
    flex-direction: column;
}
.bolderText{
    font-family: 'Lato', sans-serif;
    font-size: 90px;
    font-weight: 900;
    color:#000;
    line-height: 1;
}
.normalText{
    font-family: 'Lato', sans-serif;
    font-size: 90px;
    font-weight: 400;
    color:#707070;
    line-height: 0.8;
}
.colorText{
    color:#B8434C;
    font-size: 50px;
    font-weight: 600;
}
.largeBtn{
    background-image: linear-gradient(to right, #B8434C , #5A1A4F);
    width: 360px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    margin-top: 32px;
    box-shadow: 5px 5px 10px #333;
    display: flex;
    flex-direction: row;
}

.smallBtn {
    background-image: linear-gradient(to right, #159957, #155799);
    background-image: -webkit-linear-gradient(to right, #159957, #155799);
    background-image: -moz-linear-gradient(to right, #159957, #155799);
    background-image: -o-linear-gradient(to right, #159957, #155799);
    background: linear-gradient(to right, #159957, #155799);
    background: -webkit-linear-gradient(to right, #159957, #155799);
    background: -moz-linear-gradient(to right, #159957, #155799);
    background: -o-linear-gradient(to right, #159957, #155799);
    width: 300px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    margin-top: 32px;
    box-shadow: 5px 5px 10px #333;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
}
.largeBtnText{
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-right: 16px;
    color:#fff;
}
.smallBtnText{
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 700;
    /*margin-right: 16px;*/
    color:#fff;
}
.detailsText{
    font-family: 'Lato', sans-serif;
    font-size: 30px;
    font-weight: 400;
    margin: 32px 0;
    color:#A0A0A0;
}
.moreText{
    font-family: 'Lato', sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin: 64px 0;
    color:#B8434C;
}

.titleBox{
    padding: 32px;
    border-radius: 16px;
    box-shadow: 2px 2px 5px #333;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    background-color: #fff;
    margin: 32px 16px;
}
.iconSmall{
    margin: 8px auto;
}
.titleText{
    font-family: 'Lato', sans-serif;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin: 32px 0;
    /*margin: 64px 0;*/
    color:#8C8C8C;
}

.describeText{
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin: 32px 0;
    color:#A0A0A0;
    text-align: center;
}
.borderTop{
    height: 15px;
    width: 100px;
    border-radius: 2px;
    background-color: #B8434C;
    margin-bottom: 32px;
    box-shadow: 2px 2px 4px #333;
}
.gap{
    margin: 32px;
}
.sectionBg1{
    background-image: url("../images/cityscapewhite.png");
    /*background-color: #cccccc;*/
    /*height: 500px;*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}
.sectionBgFtr{
    /*background-image: url("../../assets/imageIcon/bgWave.png");*/
    background-color: #0c4aac;
    height: 500px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}
.sectionBg{
    background-image: url("../images/circular.png");
    /*background-color: #cccccc;*/
    /*height: 500px;*/
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
}

.cTextBox{
    width: 450px!important;
    margin: 16px auto!important;
    border-width: 1px!important;
    border-color: #8C8C8C!important;
    border-radius: 25px!important;
    padding: 0 16px!important;
    height: 50px!important;
    font-size: 20px!important;
    color:#000!important;
    /*font-weight: 600!important;*/
}

.phone{
    display: none;
}
.hdrphoto{
    width: 156px;
}
.warn{
    background-color: #faebcc;
    color: #8a6d3b;
}
.done{
    color: #3c763d;
    background-color: #d6e9c6;
}
.businessPage{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.businessImage{
    margin: 32px auto;
    justify-content: center;
    align-items: center;
    display: flex;
    height: auto;
    width: 70%;
}
@media only screen and (max-width:1650px){   
    .homebg{
        width: 68%;
    } 
    .sectionMain{
        height: auto;
    }
    .businessPage{
        height: 100vh;
    }
}

@media only screen and (max-width:1400px){
    .homeFlex{
        margin-left: 32px;
        margin-right: 32px;
        margin-bottom: 0;
        margin-top: 64px;
    }
    .homebg{
        width: 56%;
    }
    .bolderText,.normalText{
        font-size: 56px;
    }
    .colorText{
        font-size: 36px;
    }
    .detailsText{
        font-size: 18px;
    }
    .titleText{
        font-size: 24px;
    }
    .describeText{
        font-size: 16px;
    }
}
@media only screen and (max-width:992px){
    .cTextBox{
        width: 300px!important;
        font-size: 16px!important;
    }
    .businessBg{
        padding: 64px 8px;
    }
    .businessPage {
        flex-direction: column;
        height: 100%;
    }
    .hdrphoto{
        /*margin-left: 16px;*/
        width: 128px;
        margin-top: 0;
        /*height: 100px;*/
    }
    .bolderText,.normalText{
        font-size: 36px;
    }
    .colorText{
        font-size: 24px;
    }
    .detailsText{
        font-size: 12px;
    }
    .describeText{
        font-size: 12px;
    }
    .common{
        flex-wrap: wrap;
    }
    .phone{
        display: block;
        margin: 16px;
    }
    .desk{
        display: none;
    }
    .section{
        height: auto;
    }
}