.teamBg{
    background: url(../assets/imageIcon/teamBg.png) no-repeat center center;
    background-size: cover;
    overflow: hidden;
    background-color: #fdfdfd;
    width: 100%;
    /*height: 100vh;*/
    display: flex;
    flex: 1;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    padding: 64px;
}
.teamName{
    font-size: 24px;
    font-weight: bolder;
    color: #fff;
    /*text-align: center;*/
}
.teamValue{
    font-size: 16px;
    color: #1eaf94;
    /*margin-bottom: 32px;*/
    /*text-align: center;*/
}
.headerText{
    text-align: center;
    font-weight: bold;
    font-size: 42px;
    color:#fff;
    /*padding: 8px 0;*/
}
.headerRed{
    color:#C94B4B!important;
    font-weight: bolder;
}
.paragraph{
    margin: 16px 0;
    font-size: 16px;
    color:#000;
}
.pText{
    font-size: 20px;
    font-weight: bold;
    color:#000;
    text-align: left;
    margin: 16px 0;
}