.itemSection{
    flex:1;
    margin-left: 32px;
    margin-right: 370px!important;
    width: 100%;
}
.cartSection{
    width: 350px;
    position: fixed;
    right: 0;
    bottom: 10px;
    top: 70px;
    background:#F8FAFF;
    /*background-color: #dddddd;*/
}

.itemBox{
    background-color: #F9F9F9;
    padding: 16px;
    margin: 16px 0;
    border: 1px solid #FFF;
    transition: 0.2s box-shadow;
}
.itemBox:hover{
    border: 1px solid #eee;
    cursor:pointer;
    box-shadow: 0 2px 2px 0 rgba(218,220,230,.6)
}
.tags{
    background-color: rgba(255,159,0,0.2);
    padding: 2px 4px;
    border-radius: 10px;
    margin:  0px 4px 4px 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.tagsText{
    font-size: 10px;
    color:#FF9F00;
    text-align: center;
}
.allergies{
    background-color: #C94B4B;
    padding: 2px 4px;
    border-radius: 4px;
    margin:  0px 4px 4px 4px;
    display: flex;
    flex-direction: row;
}
.allergyText{
    font-size: 10px;
    color:#fff;
    text-align: center;
}
.addNumber{
    background-image: none;
    padding: 4px 16px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: auto;
    border-radius: 4px;
}
.addBtn{
    background-image: linear-gradient(to right, #c94b4b , #4b134f);
    padding: 4px 16px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: auto;
    border-radius: 4px;
}
.addText{
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-align: center;
}

.addtoCart{
    /*margin-top: 4px;*/
    width: 30px;
    cursor: pointer;
    /*-webkit-transition: transform 0.2s; !* For Safari 3.1 to 6.0 *!*/
    /*transition: transform 0.2s;*/
}
/*new css*/
.singleItem{
    padding: 8px 0pc 8px 8px;
    margin: 8px 0;
    /*border-bottom: 1px solid;*/
    cursor:pointer;
}
.common{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap!important;
}
.gapPromo{
    margin: 8px 16px;
}
.categorySmall{
    font-size: 12px;
    font-weight: 400;
    color: #C94B4B;
}
.categoryLarge{
    font-size: 18px;
    font-weight: 700;
    color: #C94B4B;
    margin-bottom: 16px;
}
.categoryModal{
    padding: 8px 4px;
    font-size: 14px;
    color: #231E1F;
    font-weight: 400;
    border-bottom: 1px solid #f4f2f2;

}
.itemContainer{
    margin-bottom: 60px;
}
.itemName{
    font-size: 14px;
    color: #231E1F;
    font-weight: 700;
    flex: 1;
    margin-bottom: 4px;
}
.itemPrice{
    font-size: 14px;
    color: #231E1F;
    font-weight: 700;
    margin-left: auto;
    margin-right: 8px;
}
.itemDescription{
    font-size: 11px;
    color:#b7b7b7;
    font-weight: 400;
}
.modItemName{
    margin-left: 32px;
    flex: 1;
    font-size: 12px;
    color:#231E1F;
    font-weight: 400;
    padding: 8px;
}
.categoryFloat{
    background-color: #fff;
    position: fixed;
    width: 100px;
    height:35px;
    border-radius: 50px;
    border: 1px solid #8e8e8e;
    text-align: center;
    font-weight: 600;
    padding-top: 4px;
    left:50%;
    bottom:10%;
    margin-left: -50px;
    cursor:pointer;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.categoryBody{
    height: 400px;
    overflow-y: scroll;
}
/*.cartMobile{*/
/*    height:56px;*/
/*    position: fixed;*/
/*    bottom:0;*/
/*    width:100%;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border-top:1px solid #ababc0;*/
/*    cursor:pointer;*/
/*    !*margin-left: -16px;*!*/
/*}*/
/*.cartbackBtn{*/
/*    display: block;*/
/*}*/
.cartTotalDiv{
    font-size: 16px;
    color:#fff;
    text-align: center;
    flex:1
}
.cartIcon{
    width: 100px;
    height: 56px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 16px;
}
.cartCount{
    position: absolute;
    top: 3px;
    right: 20px;
    background: linear-gradient(to right, #C94B4B, #4B134F);
    height: 20px;
    /* padding: 5px; */
    width: 20px;
    font-size: 12px;
    color: white;
    border-radius: 10px;
}
.mobileCart{
    width: 100%;
    height: 100%;
    z-index: 1111111;
    position: fixed;
    background: rgb(250, 250, 251);
    padding: 16px 0px 0px 16px;;
    top: 0px;
    /*margin-top: 16px;*/
}
.addressSelect{
    font-size: 12px;
    cursor: pointer;
    padding: 8px;
    align-items: center;
    color: #9B9B9B;
    font-weight: 600;
}
.selectedSetMeal {
    background-color: #fff;
    padding: 8px;
    margin: 8px 0;
}
.itemCatHeader{
    font-size: 16px;
    font-weight: 700;
    /* color: var(--light); */
    color: #C94B4B;
    /*margin: 16px 0;*/
    padding: 8px 0;
    border-bottom: 1px solid #ced4da;
    cursor: pointer;
    transition: 0.5s margin-left;
}
.buttonPadding{
    padding:16px 0;
    width: 100%;
}
.setMealSubMenu{
    width: 30%;
    padding:8px 0px;
    background-color: #ffffff;

}
.activeSetMeal{
    color:#fff!important;
    background:linear-gradient(to right, #C94B4B, #4B134F);;
    transition: 0.5s color ease-out;
    cursor: pointer;
    text-align: center;
}
.itemCatHeaderMini{
    font-size: 14px;
    font-weight: 700;
    /* color: var(--light); */
    color: #898383;
    margin: 8px 0;
    padding: 4px 0;
    border-bottom: 1px solid #ced4da;
    cursor: pointer;
    text-overflow: ellipsis;
    width: 100%;
    overflow-wrap: break-word;
}
.setMealItemSelect{
    color: rgb(40, 44, 63);
    font-weight: 700;
    /*border: 1px solid rgb(21, 87, 153);*/
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    position: relative;
}
.setMealItem{
    font-size: 12px;
    margin: 4px;
    padding: 8px;
    cursor: pointer;
    width:100%;
}
.orderCount{
    height: 16px;
    width: 20px;
    border-radius: 4px;
    background: linear-gradient(to right, #C94B4B, #4B134F);
    color: #fff;
    font-size: 11px;
    text-align: center;
    /* margin: 0 8px; */
    margin-top: 2px;
    margin-right: 4px;
    font-weight: 700;
}
.setMealWarn{
    font-size: 10px;
    color: #fd7e14;
    text-align: center;
    font-weight: 600;
}

/*cart */

.cartContainer{
    display: none;
}
.cartDelete{
    width: 30px;
    justify-content: center;
    align-items: center;
    margin-right: 16px
}
.cartItemsContainer{
    flex: 1;
    display: flex;
    flex-direction: column;
    /*border: 1px solid;*/
    margin-right: 16px;
    padding: 16px;
    border-radius: 10px;
    overflow-y: scroll;
}
.alertMessage{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color:orange;
}
.imageText{
    display:flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;

    /*justifyContent:center",alignItems:"center",flex:1,flexDirection:"column"*/
}
.cartItems{
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid;
}
.cartItemTitle{
    font-size: 18px;
    font-weight: 600;
    flex: 1;
    color: #676C76;
}
.cartModTitle{
    font-size: 14px;
    font-weight: 400;
    flex: 1;
    font-style: italic;
    padding: 0px 4px;
}
.cartPriceTitle{
    font-size: 18px;
    font-weight: 600;
    flex: 1;
    text-align:right;
    color:#C94B4B;
}
.cartCrossDiv{
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
}
.cartBottom{
    text-align: center;
    margin-top: 18px;
}
.cartButton{
    cursor: pointer;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight:700;
    transition: transform 0.5s;
}
.cartItemCount{
    margin:0px 8px;
    font-size: 18px;
    color:#C94B4B;
}
.innerCart{
    display: flex;
    flex-direction: row;
    padding:16px 16px 0 16px;
}
.cartPlus{
    width: 25px!important;
    height: 25px;
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #C94B4B;
    margin: 6px;
    cursor: pointer;
    box-shadow: 1px 1px 0px 0px #b9b9b9;
}
.cartPlus:hover{
    background: linear-gradient(to right, #C94B4B, #4B134F);
    color:#fff;
    transition: 0.2s background-color;

}
.cartRemove{
    width: 20px;
    cursor:pointer;
}
.cartRemove:hover{
    color:red;
    opacity:0.4;
    transition: 0.2s color;
}
.cartMobile{
    height:56px;
    position: fixed;
    bottom:0;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top:1px solid #ababc0;
    cursor:pointer;
}
.cartbackBtn{
    display: block;
}
.cartTotalDiv{
    font-size: 16px;
    color:#fff;
    text-align: center;
    flex:1
}
.cartIcon{
    width: 100px;
    height: 56px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 16px;
}
.cartCount{
    position: absolute;
    top: 3px;
    right: 20px;
    background: red;
    height: 20px;
    /* padding: 5px; */
    width: 20px;
    font-size: 12px;
    color: white;
    border-radius: 10px;
}
.bodyOf, .bodyOff , .offBody{
    overflow-y:hidden!important;
}

.promo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
}
.promoBody{
    background-image: url("../image/background.png");
    width: 300px;
    /*background-color: #cccccc;*/
    /*height: 500px;*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
}
.typeBody{
    width: 100%;
    text-align: center;
    margin: 16px;
    padding: 16px;
    background-color: #fff;
    position: relative;
}
.overlayPromo {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1020;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    /*background-color: #fff;*/
    overflow-x: hidden;
    transition: 0.5s;
    animation-name: popAnim;
    animation-duration: 0.2s;
}
.closePromo{
    margin-left: auto;
    position: absolute;
    top: -15px;
    right: -8px;
}
.promoLargeText{
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 8px;
}
.centerScreen{
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
}

.typeBox{
    flex: 1;
    justify-content: center;
    align-items: center;
    background: #8C8C8C;
    border: 1px solid #b7b7b7;
    border-radius: 4px;
    margin: 4px;
    padding-bottom: 8px;
}
.typeText{
    font-size: 12px;
    font-weight: 600;
    color:#b7b7b7;
}
.storeTableBox{
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
}
.storeTable{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 80px;
    margin: 8px 4px;
    text-align: center;
    background-color: #f3f5f7;
    border-radius: 4px;
}
.tableText{
    font-size: 14px;
    font-weight: 700;
    color:#000;
    text-align: center;
}