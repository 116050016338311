@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
.hsImage{
    margin: 16px auto;
    display: flex;
    height: 62px;
    width: 280px;
    object-fit: contain
}
.localText{
    color:#0C4AAC;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
}
.outerDiv{
    background-color: #F5F5F5;
    border-radius: 32px;
    margin: 24px;
    padding: 10px;
    box-shadow: 1px 1px 1px #cccccc;
    -webkit-box-shadow: 1px 1px 1px #cccccc;
    -moz-box-shadow: 1px 1px 1px #cccccc;
    font-family: 'Open Sans', sans-serif;

}
.innerDiv{
    background-image: url("1.png");
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    flex-direction: column;
    border-radius: 18px;
    background-color: #FFF;
    -webkit-box-shadow: 1px 4px 2px #d2d2d2;
    -moz-box-shadow: 1px 4px 2px #d2d2d2;
    box-shadow: 1px 4px 2px #d2d2d2;
    font-family: 'Open Sans', sans-serif;
    padding: 24px 0;
}
.nameDiv{
    flex:1;
    padding:8px;
    margin-top: 8px;
    margin-bottom: 16px
}
.bigText{
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #1C1C1C;
}
.smallBoldText{
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #1C1C1C;
}
.smallText{
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #1C1C1C;
}
.itemGradient{
    background-image: linear-gradient(to right, #c94b4b , #4b134f);
    padding: 16px 0;
    margin-bottom: 16px;
}
.itemGradientInner{
    padding: 0pc 8px;
    display: flex;
    flex-direction: row;
}
.prices{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 8px;
}
.bigTextCenter{
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #fff;
    text-align: center;
    flex:1;
}
.itemText{
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    padding: 4px 0;
    text-align: center;
    flex:1
}
.outerText{
    font-size: 20px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    padding: 16px 0;
}
.invoiceBg{
    /*background: url(../assets/imageIcon/gradient.png) bottom no-repeat;*/
    background-size: contain;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
    padding-bottom: 64px;
    /*height: 100vh;*/
}
.invoiceBtn{
    background-image: linear-gradient(to right, #c94b4b , #4b134f);
    width: 93%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-top: 16px;
    box-shadow: 1px 1px 2px #333;
    -webkit-box-shadow:  1px 1px 2px #333;
    -moz-box-shadow:  1px 1px 2px #333;
    display: flex;
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
    padding: 16px;
}
.getBtnText{
    font-family: 'Open Sans';
    font-size: 22px;
    font-weight: 700;
    height: 50px;
    /*margin-right: 16px;*/
    color:#fff;
    justify-content: center;
    align-items: center;
    display: flex;
}
.totalDiv{
    display: flex;
    flex-direction: row;
    border-top: 1px solid #707070;
    margin-top: 16px;
    width: 100%;
    justify-content: flex-end;
    margin-left: 16px;
}
.imageClass{
    height: 100px;
    width: 100px;
    border-radius: 50px;
    background-color: #aaa;
    margin: 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imageBigText{
    font-size: 50px;
    color:#fff;
    font-weight: 700;
}
.payText{
    flex-direction: row;
    font-size: 12px;
    color:#707070;
    text-align: center;
    padding: 16px 0;
}
.accountText{
    font-size: 14px;
    color: #707070;
    margin: 4px 16px;
    font-weight: 700;
}
.valueText{
    font-size: 18px;
    color: #000;
    margin: 4px 16px;
}
.cardWrapper{
    width: 92%;
    padding: 16px;
    /*height: 40px;*/
    margin: 30px auto;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #F5F5F5;
    border-radius: 4px;
    background-color: #F5F5F5;
    position: relative;
}
.payButton {
    display: block;
    height: 50px;
    width: 100%;
    font-size: inherit;
    background-color: #0C4AAC;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    box-shadow: black;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.greenButton{
    padding: 16px;
    width: 92%;
    height: 50px;
    background-color: #57B182;
    margin: 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 4px;
    color: #FFFFFF;
    border-radius: 4px;
}
.redButton{
    padding: 16px;
    width: 92%;
    height: 50px;
    background-color: red;
    margin: 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 4px;
    color: #FFFFFF;
    border-radius: 4px;
}
.payDone {
    position: fixed;
    /*display: none;*/
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.5);
    z-index: 2;
    cursor: pointer;
}
.payDoneDiv{
    position: absolute;
    /* text-align: center; */
    height: 230px;
    width: 300px;
    top: 50%;
    left: 50%;
    background-color: #f2f2f2;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    border-radius: 21px;
}
.payDivText{
    font-size: 26px;
    font-weight: 600;
    color:#000
}
@media only screen and (min-width: 768px) {
    .outerDiv{
        width: 600px;
        margin: 24px auto;
    }
    .greenButton,.payButton{
        width: 600px;
        margin: 10px auto;
    }
}