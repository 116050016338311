.header{
    font-weight: 400;
}
.body{
    font-weight: 300;
}
.subtext{
    font-weight: 200;
}
.floatBackground{
    background-color: #fff;
    overflow: hidden;
    width: 80%;
    height: 80vm;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-align: left;
    margin: 64px auto;
}
.paraHeader{
    margin-left: 5%;
    margin-right: 5%;
    font-weight: 600;
    font-size: 28px;
    color: #333;
}
.paraSubtext{
    margin-left: 5%;
    margin-right: 5%;
    font-weight: 400;
    font-size: 24px;
    color: #0c4aac;
}
.paraText{
    margin-left: 5%;
    margin-right: 5%;
    font-weight: 400;
    font-size: 22px;
    margin-top: -10px;
}
.paraEndText{
    margin-left: 5%;
    margin-right: 5%;
    font-weight: 300;
    font-size: 22px;
}