.downloadBg{
    /*background: url(../assets/imageIcon/middleBg.png) no-repeat center center;*/
    /*background-size: contain;*/
    /*height: 100%;*/
    overflow: hidden;
    background: linear-gradient(#000, #24243A);
    width: 100%;
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 64px;
}
.downloadImages{
    /*background: url(../assets/imageIcon/middleBg.png) no-repeat center center;*/
    /*background-size: contain;*/
    /*height: 100%;*/
    overflow: hidden;
    width: 100%;
    height: 80vh;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 64px;
    margin-top: 20px;
}
.downloadHead{
    /*background: url(../assets/imageIcon/middleBg.png) no-repeat center center;*/
    /*background-size: contain;*/
    /*height: 100%;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
.gap{
    width: 224px;
    margin: 20px 4px!important;
}
.common{
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width: 1650px){
    .downloadImage{
        height: auto;
        width: 65%;
    }
}
@media only screen and (max-width: 1450px){
    .downloadImage{
        height: auto;
        width: 65%;
    }
}

@media only screen and (max-width: 1366px){
    .downloadImage{
        height: auto;
        width: 65%;
    }
}

@media only screen and (max-width: 1200px){
    .downloadImage{
        height: auto;
        width: 55%;
    }
    .gap{
        width: 196px;
    }

}
@media screen and (max-width: 992px),
screen and (max-height: 1024px) {
    .downloadBg{
        height: 100%;
    }
    .gap{
        width: 184px;
    }
}
@media screen and (min-height: 800px) {
    .downloadBg{
        height: 100vh;
    }
    .gap{
        width: 164px;
    }
}
@media only screen and (max-width: 992px) {
    .downloadBg{
        flex-direction: column;
        height: 100%;
    }
    .downloadImage{
        height: auto;
        width: 70%;
    }
    .gap{
        width: 156px;
    }
}
@media only screen and (max-width: 767px) {
    .gap{
        width: 136px;
    }
    .downloadHead{
        /*background: url(../assets/imageIcon/middleBg.png) no-repeat center center;*/
        /*background-size: contain;*/
        /*height: 100%;*/
        display: block;
    }
    .textClass{
        margin-left: 0;
    }
}
